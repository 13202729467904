@import "../../../../stylesheets/atlas/prerequisite";

.at-attendee-summary__stat-cont {
  display: grid;
  gap: 8px;
  grid-template-columns: repeat(5, 1fr);

  .at-form__content {
    padding: 12px;
  }

  @media screen and (max-width: 1200px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @media screen and (max-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media screen and (max-width: 720px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media screen and (max-width: 576px) {
    grid-template-columns: 1fr;
  }
}

.at-attendee-payment {
  display: flex;
  align-items: center;
  gap: 8px;

  p {
    text-wrap: wrap;
    width: fit-content;
  }

  .at-attendee-payment__btn {
    display: flex;
    width: 40px;
    height: 40px;
    padding: 8px 12px;
    margin: 4px !important;
    justify-content: center;
    align-items: center;
    border-radius: 999px;
    border: 1px solid #d1d5db;
    background: #ffffff;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);

    svg {
      display: flex;
      width: 20px;
      height: 20px;
      fill: #3b82f6;
      justify-content: center;
      align-items: center;
    }

    @media screen and (max-width: 800px) {
      margin-left: auto;
    }
  }
}

.at-attendee-ticket__radio-mobile {
  @include max-screen($sidebar-breakpoint) {
    display: flex;
    align-items: center;
    transform: translateX(-25px);

    .at-table--checkbox-mobile {
      transform: translateX(8px);
    }

    .MuiSvgIcon-root-277 {
      width: 20px;
      height: 20px;
    }
  }
}

.at-attendee__pdf-preview {
  margin-bottom: 2rem;
  height: 70vh;
  @media screen and (max-width: 767px) {
    height: 60vh;
  }
}

.at-attendee-reject {
  .DraftEditor-root {
    resize: vertical;
    min-height: 200px;
    height: 200px;
    max-height: 70vh;
  }
}

.at-custom_ckeditor-reject {
  .ck-editor__editable {
    resize: vertical;
  }
}

.at-attendee__agent-detail {
  display: flex;
  padding: 20px;
  align-items: flex-start;
  gap: 16px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;

  img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: contain;
  }

  .at-attendee__check-in-out {
    display: flex;
    gap: 16px;
    width: 100%;
    padding-left: 16px;
    border-left: 1px solid #D1D5DB;
    font-size: 14px;
    color: #111827;
    font-weight: 500;
  }

  @include max-screen($sidebar-breakpoint) {
    flex-direction: column;

    .at-attendee__check-in-out {
      padding-left: 0px;
      padding-top: 16px;
      border-left: none;
      border-top: 1px solid #D1D5DB;
    }
  }
}

.at-event-attachment-share_btn {
  position: absolute;
  padding: 0.2rem;
  top: 6.5rem;
  right: 4.5rem;
  border-radius: 50%;
  background-color: #ffffff;
  border: 4px solid #007bff;
  width: 55px;
  min-width: 55px;
  height: 55px;
  min-height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;

  &-tooltip {
    top: 4.5rem;
    right: 8rem;
  }

  @media (max-width: 499px) {
    width: 45px;
    min-width: 45px;
    height: 45px;
    min-height: 45px;
    top: clamp(4rem, 15vw, 6.5rem);
    right: clamp(3rem, 15vw, 5rem);

    &-tooltip {
      top: clamp(2rem, 10vw, 6.5rem);
      right: clamp(0rem, 6vw, 2rem);

      &_popper {
        right: 2rem;
        left: initial !important;
      }
    }
  }
}

.at-event-training__mobile-stat {
  display: flex;
  padding: 4px 8px;
  border-radius: 8px;
  border: 1px solid #D1D5DB;

  label {
    color: #111827;
    font-size: 14px;
    font-weight: 600;
    line-height: 150%;
  }

  p {
    color: #1F2937;
    font-size: 14px;
    line-height: 150%;
  }
  
  > div {
    gap: 4px;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-left: 1px solid #D1D5DB;

    &:first-child {
      border-left: none;
    }
  }
}

.at-event-training__share {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(5, 1fr);

  .at-share-modal__button-container {
    margin: 0px;
    display: flex;
    height: 96px;
    min-width: 80px;
    max-width: 98px;
    padding: 8px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 8px;
    flex: 1 0 0;
    cursor: pointer;
    border-radius: 8px;
    border: 1px solid #D1D5DB;
    box-shadow: 0px 1px 1px 0px rgba(0, 0, 0, 0.06);
  }
  
  .at-share-modal__button {
    width: 36px;
    height: 36px;
    min-width: 36px;
    min-height: 36px;
    padding: 8px;
    margin: 0px;
  }

  @media screen and ( max-width: 767px ) {
    grid-template-columns: repeat(3, 1fr);
  }
}